<template>
  <div class="employee-info">
    <div class="title">成员信息</div>
    <div class="header-actions">
      <el-button size="small" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
      <!-- <el-button size="small" @click="handleEdit">编辑</el-button>
      <el-button size="small" @click="handleDelete">删除</el-button> -->
    </div>
    <div class="user-info-box">
      <div class="user-info">
        <div class="avatar-wrap">
          <userAvatar :url="user.avatar" :round="false"></userAvatar>
        </div>
        <div class="info-options">
          <div class="name">
            <span>{{ user.name }}</span>
          </div>
          <div class="alis">别名: {{ user.alias }}</div>
          <div class="alis">账号: {{ user.userid }}</div>
        </div>
      </div>
      <div class="dashed-border"></div>
      <div class="info-item">
        <div class="info-item__label">
          <span class="red-color">*</span>
          手机:
        </div>
        <div class="info-item__content">{{ user.mobile }}</div>
      </div>
      <div class="info-item">
        <div class="info-item__label">座机:</div>
        <div class="info-item__content">{{ user.telephone }}</div>
      </div>
      <div class="info-item">
        <div class="info-item__label">邮箱:</div>
        <div class="info-item__content">{{ user.email }}</div>
      </div>
      <div class="info-item">
        <div class="info-item__label">地址:</div>
        <div class="info-item__content">{{ user.address }}</div>
      </div>
      <div class="dashed-border"></div>
      <div class="info-item">
        <div class="info-item__label">
          <span class="red-color">*</span>
          部门:
        </div>
        <div class="info-item__content">
          <template v-for="(item, index) in user.departmentName" :key="item">
            <span type="departmentName" :openid="item" @click="isOpen($event, index)">{{ item }}</span>
            <span v-if="index !== user.departmentName.length - 1">、</span>
          </template>
        </div>
      </div>
      <!-- <div class="info-item">
        <div class="info-item__label">标签:</div>
        <div class="info-item__content"></div>
      </div> -->
      <div class="info-item">
        <div class="info-item__label">职务:</div>
        <div class="info-item__content">{{ user.position }}</div>
      </div>
      <div class="info-item">
        <div class="info-item__label">身份:</div>
        <div class="info-item__content">{{ user.identity?.desc }}</div>
      </div>
      <div class="info-item">
        <div class="info-item__label">负责部门:</div>
        <div class="info-item__content">
          <template v-for="(item, index) in user.dutyDepartmentName" :key="item">
            <span type="departmentName" :openid="item">{{ item }}</span>
            <span v-if="index !== user.dutyDepartmentName.length - 1">、</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, reactive, watch } from '@vue/runtime-core'
import { delEmployee, getEmployeeInfo } from '@/apis'
import { useDeleteConfirm } from '@/hooks/ui'
import { ElMessage } from 'element-plus'
export default {
  name: 'employeeInfo',
  props: {
    id: { type: String, required: true },
    department: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  emits: ['delete', 'edit', 'back'],
  setup(props, { emit }) {
    const deleteConfirm = useDeleteConfirm()
    const state = reactive({
      user: {}
    })
    const initEmployeeInfo = async id => {
      if (!id) return false
      const { data } = await getEmployeeInfo(id)

      state.user = data || {}
    }
    watch(
      () => props.id,
      id => {
        initEmployeeInfo(id)
      },
      { immediate: true }
    )
    const handleBack = () => {
      emit('back')
    }
    const isOpen = (e, index) => {
      console.log('index: ', index)
    }
    // 编辑员工
    const handleEdit = () => {
      emit('edit', state.user)
    }
    // 删除员工
    const handleDelete = async () => {
      const confirm = await deleteConfirm('您确认要删除该信息吗?')
      if (confirm) {
        await delEmployee({ id: props.id, department: props.department.id })
        ElMessage.success('删除成功')
        emit('delete')
      }
    }
    return { ...toRefs(state), handleBack, handleEdit, handleDelete, isOpen }
  }
}
</script>
<style lang="scss" scoped>
@import './employee-info.scss';
</style>
