<template>
  <div class="employee-edit">
    <div class="title">{{ employee.id ? '编辑成员' : '添加成员' }}</div>
    <div class="actions-wrap">
      <el-button size="small" type="primary" v-if="!employee.id" @click="handleSave(true)">保存并继续添加</el-button>
      <el-button size="small" @click="handleSave(false)">保存</el-button>
      <el-button size="small" @click="handleCancel">取消</el-button>
    </div>
    <div class="form-wrap-box">
      <div class="form-wrap">
        <el-form
          :model="form"
          ref="employeeFormRef"
          :rules="rules"
          inline-message
          size="small"
          label-position="right"
          label-width="132px"
        >
          <div class="custom-form-item">
            <el-upload
              class="avatar-uploader"
              action="/"
              :accept="settings.accept"
              :before-upload="beforeUpload"
              :http-request="httpRequest"
              :show-file-list="false"
            >
              <div class="avatar-uploader-icon">
                <img :src="form.profileUrl" alt="" />
              </div>
            </el-upload>
            <div :class="['0', '1'].includes(usernameState) ? 'el-form-item__content error' : 'el-form-item__content'">
              <el-input
                size="small"
                v-model="form.username"
                @blur="getUsername"
                placeholder="姓名"
                maxlength="20"
              ></el-input>
              <span v-if="usernameState === '0'">请输入姓名</span>
              <!-- <span v-if="usernameState === '1'">姓名格式应为（中文或英文)</span> -->
            </div>
            <el-input size="small" v-model="form.aliasName" placeholder="别名"></el-input>
          </div>
          <el-form-item prop="account">
            <template #label>
              <span>账号</span>
              <el-tooltip placement="top">
                <template #content><span class="input-tip">此账号由系统生成，只允许修改一次</span></template>
                <i class="iconfont icon-help"></i>
              </el-tooltip>
              :
            </template>
            <el-input
              size="small"
              v-model="form.account"
              :disabled="employee.id && form.account"
              placeholder="成员唯一标识，设定以后不支持修改"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别&nbsp;:" prop="gender" class="radio-item">
            <el-radio-group v-model="form.gender" style="height: 100%">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="dashed-border"></div>
          <el-form-item label="手机&nbsp;:" prop="mobile">
            <el-input size="small" v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item prop="telphone" label="座机&nbsp;:">
            <el-input v-model="form.telphone" size="small"></el-input>
          </el-form-item>
          <el-form-item prop="email" label="邮箱&nbsp;:">
            <el-input v-model="form.email" size="small"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="地址&nbsp;:">
            <el-input v-model="form.address" size="small"></el-input>
          </el-form-item>
          <div class="dashed-border"></div>
          <el-form-item prop="departments" class="departments" label="部门&nbsp;:">
            <div class="deparemtn-wrap">
              <div class="department-item" v-for="(department, index) in form.departments" :key="department.id">
                <span>{{ department.name }}</span>
                <menu-action>
                  <!-- <menu-action-item @click.stop="onActionClick('setMain', department)">设为主部门</menu-action-item> -->
                  <menu-action-item
                    @click.stop="onActionClick('department-delete', department, index, form.departments)"
                  >
                    删除
                  </menu-action-item>
                </menu-action>
              </div>
              <el-button type="text" size="small" @click="handleEditClick('department')">修改</el-button>
            </div>
          </el-form-item>
          <!-- <el-form-item prop="label" label="标签&nbsp;:">
            <el-button type="text" size="small">修改</el-button>
          </el-form-item> -->
          <el-form-item prop="position" label="职务&nbsp;:">
            <el-input v-model="form.position"></el-input>
          </el-form-item>
          <el-form-item prop="identity" label="身份&nbsp;:" class="radio-item">
            <el-radio-group v-model="form.identity" style="height: 100%">
              <el-radio label="0">普通成员</el-radio>
              <el-radio label="1">上级</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="负责部门&nbsp;:" v-if="form.identity === '1'">
            <div class="deparemtn-wrap">
              <div class="department-item" v-for="(department, index) in form.dutyDepartmentList" :key="department.id">
                <span>{{ department.name }}</span>
                <menu-action>
                  <!-- <menu-action-item @click.stop="onActionClick('setMain', department)">设为主部门</menu-action-item> -->
                  <menu-action-item
                    @click.stop="onActionClick('duty-department-delete', department, index, form.dutyDepartmentList)"
                  >
                    删除
                  </menu-action-item>
                </menu-action>
              </div>
              <el-button
                type="text"
                v-if="form.departments.length > 1"
                size="small"
                @click="handleEditClick('dutyDepartment')"
              >
                修改
              </el-button>
            </div>
            <!-- <department-select ref="dutyRef" v-model="form.dutyDepartment"></department-select> -->
          </el-form-item>
        </el-form>
      </div>
      <div class="actions-wrap">
        <el-button size="small" type="primary" v-if="!employee.id" @click="handleSave(true)">保存并继续添加</el-button>
        <el-button size="small" @click="handleSave(false)">保存</el-button>
        <el-button size="small" @click="handleCancel">取消</el-button>
      </div>
    </div>
  </div>
  <!-- 部门选择 -->
  <!-- <transfer-dialog
    ref="transferDialog"
    title="选择部门"
    selectType="department"
    children-key="childList"
    :selectDisabledState="selectDisabledState"
    :selectableList="form.departments"
    :selecteds="form.departmentSelects"
    :topDepartment="topDepartment"
    :search="onSearch"
    :loadedStateBack="onLoadedStateBack"
    @confirm="onSelectDepartmentConfirm"
    v-model:visible="showSelectDepartment"
  ></transfer-dialog> -->
  <OrganizationSelect
    :filter="true"
    :config="config"
    @cancel="cancelOrgan"
    @confirm="onSelectDepartmentConfirm"
    :select_data="form.departmentSelects"
  />
</template>
<script>
import { nextTick, reactive, ref, toRefs, watch } from 'vue'
import OrganizationSelect from '@/components/organization-select/index.vue'
import { useFileUpload } from '@/hooks/upload'
import { PROFILE_UPLOAD_HOLDER } from '@/common/const'
import MenuAction, { MenuActionItem } from '@/views/components/menu-action/index.vue'
// import { useDepartment } from '@/hooks/sys'
import { getFileShowUrl, debounce } from '@/common/utils'
import { phoneValidator, mobildValidator, emailValidator } from '@/common/validator'
import { useDict } from '@/hooks/dict'
// import DepartmentSelect from '@/components/department-select'
import { saveEmployee, updateEmployee, getDepartmentList, getLazyTreeDepartment } from '@/apis'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
const handleRegValue = value => {
  let flag = false
  let reg = /^\w+$/
  for (let char of value) {
    if (!reg.test(char) && char != '·' && char != '·' && char != '-' && char != '@') {
      flag = true
      return flag
    }
  }
  return flag
}
const validatePass = (rule, value, callback) => {
  if (value && value.length < 4) callback(new Error('请输入账号长度不能小于4位数'))
  let reg = /^\w+$/
  if (!reg.test(value) && handleRegValue(value)) {
    callback(new Error('账号只是能有字母、数字(·-_@)组成'))
  }
  if (value == '') {
    callback(new Error('请输入账号'))
  }
  callback()
}
const rules = {
  account: [{ validator: validatePass, required: true, trigger: ['blur', 'change'] }],
  gender: [{ required: true, message: '请选择性别' }],
  mobile: [
    { required: true, message: '请输入手机号码' },
    {
      validator: mobildValidator,
      trigger: ['blur', 'change']
    }
  ],
  telphone: [
    {
      validator: phoneValidator,
      trigger: ['blur', 'change']
    }
  ],
  email: [
    {
      validator: emailValidator,
      trigger: ['blur', 'change']
    }
  ]
}
export default {
  name: 'employeeEdit',
  components: { MenuAction, MenuActionItem, OrganizationSelect },
  props: {
    employee: {
      type: Object,
      default: () => ({})
    },
    department: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['cancel', 'success'],
  setup(props, { emit }) {
    const store = useStore()
    const { employee, department } = toRefs(props)
    const employeeInfo = employee.value
    const departmentInfo = department.value
    const employeeFormRef = ref(null)
    const dutyRef = ref(null)
    const transferDialog = ref(null)
    const state = reactive({
      showSelectDepartment: false,
      selectDisabledState: false,
      transferDialogType: '', //部门选择类型
      topDepartment: store.state.topDepartment || {},
      usernameState: null,
      config: {
        show: false
      }
    })
    const { getDict } = useDict()
    const positionsList = getDict('positions')
    let departmentList = employeeInfo.departmentList || []

    if (!departmentList.find(item => item.id === departmentInfo.id)) {
      if (Object.keys(departmentInfo).length > 0) {
        departmentList.unshift(departmentInfo)
      }
    }
    const form = reactive({
      profileUrl: employeeInfo.avatar || PROFILE_UPLOAD_HOLDER,
      profileId: '',
      username: employeeInfo.name || '',
      aliasName: employeeInfo.alias || '',
      account: employeeInfo.userid || '',
      gender: employeeInfo.gender?.id || '',
      mobile: employeeInfo.mobile || '',
      telphone: employeeInfo.telephone || '',
      email: employeeInfo.email || '',
      address: employeeInfo.address || '',
      departments: departmentList,
      label: '',
      position: employeeInfo.position || '',
      identity: employeeInfo.identity?.id || '',
      dutyDepartment: employeeInfo.dutyDepartment || [],
      dutyDepartmentList: [],
      departmentSelects: [] //部门选中项
    })
    // state.topDepartment = form.departments.filter(c => c.id === '1')[0] || {}

    //获取负责部门最新的数据
    const setDutyDepartmentList = (origin, target) => {
      let listData = []
      let list = JSON.parse(JSON.stringify(origin))
      list.forEach(item => {
        if (target.length > 0) {
          target.forEach(c => {
            if (item.id === c) {
              listData.push(item)
            }
          })
        } else {
          listData.push(item)
        }
      })
      return listData
    }
    const validForm = () => {
      return new Promise(resolve => {
        employeeFormRef.value.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    }
    const resetForm = () => {
      employeeFormRef.value.resetFields()
      form.username = ''
      form.aliasName = ''
      form.profileUrl = ''
      form.departments = []
      form.dutyDepartment = []
      form.dutyDepartmentList = []
      state.usernameState = null
    }
    const getUsername = e => {
      //获取姓名校验
      let val = e.target.value
      state.usernameState = validatorUserName(val)
    }
    const validatorUserName = val => {
      // let reg = /^[\u4e00-\u9fa5]*$/
      // let reg2 = /^[a-zA-Z]*$/
      // let flag = null
      // if (!val) {
      //   flag = '0'
      // } else if (reg.test(val) || reg2.test(val)) {
      //   flag = null
      // } else {
      //   flag = '1'
      // }
      // return flag
      return !val ? '0' : null
    }
    const handleCancel = () => {
      emit('cancel')
    }
    // 保存 goOn 是否继续
    const getParams = () => {
      const departmentIds = []
      const departmentNames = []
      let dutyIds = []
      let dutyNames = []
      form.departments.forEach(item => {
        departmentIds.push(item.id)
        departmentNames.push(item.name)
        if (form.dutyDepartmentList.length > 0) {
          form.dutyDepartmentList.forEach(c => {
            if (c.id === item.id) {
              dutyNames.push(item.name)
              dutyIds.push(item.id)
            }
          })
        }
      })

      // const dutyList = dutyRef.value ? dutyRef.value.getSelectedList() : null

      const params = {
        avatar: form.profileUrl,
        name: form.username,
        userid: form.account,
        alias: form.aliasName,
        gender: form.gender,
        mobile: form.mobile,
        telephone: form.telphone,
        email: form.email,
        address: form.address,
        departmentName: departmentNames,
        department: departmentIds,
        dutyDepartmentName: dutyNames,
        dutyDepartment: dutyIds,
        position: form.position,
        // positionNos: [form.position],
        // positionNames: [positionsList.find(item => item.id === form.position)?.name],
        identity: form.identity,
        type: '1',
        status: '2'
      }
      if (employeeInfo?.sort) {
        params.sort = employeeInfo.sort || ''
      }
      return params
    }
    const handleSave = debounce(async goOn => {
      const employeeId = props.employee?.id
      state.usernameState = validatorUserName(form.username)
      if (['0', '1'].includes(state.usernameState)) {
        return
      }
      const valid = await validForm()
      if (!valid) return
      const params = getParams()

      if (params.department.length === 0) {
        ElMessage.warning('请选择部门')
        return
      }
      let res = {}
      if (employeeId) {
        params['id'] = employeeId
        res = await updateEmployee(params)
      } else {
        res = await saveEmployee(params)
      }
      if (res.isOk) {
        ElMessage.success(employeeId ? '编辑成功' : '添加成功')
        emit('success')
      }
      if (!goOn) {
        // 关闭
        emit('cancel')
      } else {
        // TODO 清空表单
        resetForm()
      }
    }, 800)

    // 头像上传相关
    const { settings, beforeUpload, httpRequest } = useFileUpload(
      file => {
        form.profileId = file.id
        form.profileUrl = getFileShowUrl(file)
      },
      { accept: '.png,.jpg', limit: 1, size: '10MB' }
    )

    // 部门action
    const onActionClick = (type, data, index, ary) => {
      if (['duty-department-delete', 'department-delete'].includes(type)) {
        ary.splice(index, 1)
        if (type === 'department-delete') {
          form.dutyDepartmentList = form.dutyDepartmentList.filter(c => c.id !== data.id)
        }
      }
    }
    const handleEditClick = async type => {
      state.transferDialogType = type
      if (type === 'dutyDepartment') {
        form.departmentSelects = await form.dutyDepartmentList
        state.selectDisabledState = true
      } else {
        form.departmentSelects = await form.departments
        state.selectDisabledState = false
      }
      nextTick(() => {
        state.config.show = true
      })
    }
    // const { state: departmentState, initDepartmentList } = useDepartment()
    // const rootName = computed(() => {
    //   return departmentState?.tree?.[0]?.name || ''
    // })
    // const deparmentList = computed(() => {
    //   const list = departmentState?.tree?.[0]?.childList || []
    //   bfsTree(list, 'type', 'department', 'childList')
    //   return list
    // })
    const onSelectDepartmentConfirm = list => {
      if (state.transferDialogType === 'dutyDepartment') {
        form.dutyDepartmentList = list
      } else {
        form.departments = list
      }
      state.config.show = false
    }
    const cancelOrgan = () => {
      state.config.show = false
    }
    /**
     * 搜索部门
     */
    const searchDepartment = async name => {
      transferDialog.value.setTransferSelectData('departmentLoading', true)
      try {
        let result = []
        if (!name) {
          const { data } = await getLazyTreeDepartment({
            parent_id:
              transferDialog.value.getTransferSelectData('navList')[
                transferDialog.value.getTransferSelectData.length - 1
              ]?.id,
            pageIndex: 1,
            pageSize: 20000
          })
          transferDialog.value.setTransferSelectData('departmentLoading', false)
          result = (data && data?.list) || []
        } else {
          const { data } = await getDepartmentList(null, name)
          result = (data && data?.list) || []
          transferDialog.value.setTransferSelectData('departmentLoading', false)
        }
        if (result.length > 0) {
          result.map(item => {
            item['type'] = 'department'
            return item
          })
        }
        transferDialog.value.setTransferSelectData('currentList', result)
        transferDialog.value.setTransferSelectData('selectDisabledState', true)
        transferDialog.value.setTransferSelectData('navList', [
          transferDialog.value.getTransferSelectData('navList')[0]
        ])
        // selectDisabledState
      } catch (error) {
        transferDialog.value.setTransferSelectData('departmentLoading', false)
      }
    }
    const onSearch = text => {
      searchDepartment(text)
    }
    //部门选择提示菜单
    const openSelect = (e, item) => {
      item.isOpen = !item.isOpen
    }
    // initDepartmentList(true)
    watch(
      () => form.identity,
      val => {
        if (val === '1') {
          let list = JSON.parse(JSON.stringify(form.departments))
          // nextTick(() => {
          //   let currentList = transferDialog.value.getTransferSelectData('currentList') //获取当前的数据
          //   //对比上级已有的部门，除了这个其他的选项不能选
          //   currentList.map(item => {
          //     list.map(c => {
          //       if (c.id === item.id) {
          //
          //       }
          //     })
          //   })
          // })
          form.dutyDepartmentList = setDutyDepartmentList(list, form.dutyDepartment)

          if (form.departments.length === 0) {
            form.identity = '0'
          }
        }
      },
      { immediate: true }
    )
    watch(
      () => form.departments,
      val => {
        if (val.length === 0) {
          form.identity = '0'
        } else {
          let list = JSON.parse(JSON.stringify(val))
          form.dutyDepartmentList = setDutyDepartmentList(list, form.dutyDepartment)
        }
      },
      { immediate: true, deep: true }
    )
    const onLoadedStateBack = () => {
      let currentList = transferDialog.value.getTransferSelectData('currentList') //获取当前的数据
      currentList.map(item => {
        form.dutyDepartmentList.map(c => {
          if (c.id === item.id) {
            item.disabled = true
          }
        })
      })
    }

    return {
      ...toRefs(state),
      form,
      rules,
      employeeFormRef,
      dutyRef,
      handleCancel,
      handleSave,
      settings,
      beforeUpload,
      httpRequest,
      onActionClick,
      // rootName,
      // deparmentList,
      handleEditClick,
      onSelectDepartmentConfirm,
      cancelOrgan,
      positionsList,
      openSelect,
      getUsername,
      transferDialog,
      onSearch,
      onLoadedStateBack
    }
  }
}
</script>
<style lang="scss" scoped>
@import './employee-edit.scss';
</style>
